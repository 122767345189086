import api from '@/api'

const state = {
  growingTerms: [],
  themes: []
}

const getters = {
  growingTerms: (state) => state.growingTerms
}

const actions = {
  async getTrends({ commit }, data) {
    console.log(data)
    try {
      const response = await api.get('/analytics/trends?type=' + data.type)
      console.log(response)
      commit('SET_GROWING_TERMS', response)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve trends:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve trends.'
      )
    }
  },
  async getThemes({ commit }, data) {
    console.log(data)
    try {
      const response = await api.get('/analytics/themes')
      console.log(response)
      commit('SET_THEMES', response)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve trends:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve trends.'
      )
    }
  },

  setData({ commit }, data) {
    commit('SET_GROWING_TERMS', data)
  }
}

const mutations = {
  SET_GROWING_TERMS(state, growingTerms) {
    state.growingTerms = growingTerms
  },
  SET_THEMES(state, themes) {
    state.themes = themes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
