import api from '@/api'

const state = {
  comments: [
    {
      id: 1,
      author: 'Sarah Johnson',
      avatar: 'https://ui-avatars.com/api/?name=Sarah+Johnson',
      content:
        "Thanks for reaching out! This sounds great and I'd love a demo.",
      likes: 17,
      comments: 9,
      timeAgo: '2 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['sales']
    },
    {
      id: 2,
      author: 'Mike Anderson',
      avatar: 'https://ui-avatars.com/api/?name=Mike+Anderson',
      content: 'Love the convenience of the product. Might subscribe soon!',
      likes: 2,
      comments: 0,
      timeAgo: '6 hours ago',
      sentiment: 'positive',
      platform: 'instagram',
      tags: ['product']
    },
    {
      id: 3,
      author: 'Ashley Rida',
      avatar: 'https://ui-avatars.com/api/?name=Ashley+Rida',
      content: 'Rubbish!',
      likes: 40,
      comments: 3,
      timeAgo: 'yesterday',
      sentiment: 'negative',
      platform: 'facebook',
      tags: []
    },
    // New comments...
    {
      id: 4,
      author: 'David Chen',
      avatar: 'https://ui-avatars.com/api/?name=David+Chen',
      content:
        'The sentiment analysis feature is incredibly accurate. Been using it for our marketing campaigns and seeing great results.',
      likes: 28,
      comments: 12,
      timeAgo: '3 hours ago',
      sentiment: 'positive',
      platform: 'youtube',
      tags: ['feature']
    },
    {
      id: 5,
      author: 'Rachel Martinez',
      avatar: 'https://ui-avatars.com/api/?name=Rachel+Martinez',
      content:
        'Integration was a nightmare. Support team took days to respond.',
      likes: 8,
      comments: 15,
      timeAgo: '1 day ago',
      sentiment: 'negative',
      platform: 'youtube',
      tags: ['product']
    },
    {
      id: 6,
      author: 'Tom Wilson',
      avatar: 'https://ui-avatars.com/api/?name=Tom+Wilson',
      content:
        'Can someone from the team help with API documentation? Having trouble with implementation.',
      likes: 5,
      comments: 7,
      timeAgo: '4 hours ago',
      sentiment: 'negative',
      platform: 'instagram',
      tags: ['feature']
    },
    {
      id: 7,
      author: 'Emma Thompson',
      avatar: 'https://ui-avatars.com/api/?name=Emma+Thompson',
      content:
        'Just started using the platform and already seeing valuable insights. The dashboard is intuitive!',
      likes: 45,
      comments: 8,
      timeAgo: '5 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['feature']
    },
    {
      id: 8,
      author: 'James Rodriguez',
      avatar: 'https://ui-avatars.com/api/?name=James+Rodriguez',
      content:
        'Monthly reports have transformed our feedback analysis. Worth every penny!',
      likes: 33,
      comments: 6,
      timeAgo: '2 days ago',
      sentiment: 'positive',
      platform: 'youtube',
      tags: ['feature']
    },
    {
      id: 9,
      author: 'Sophie Wang',
      avatar: 'https://ui-avatars.com/api/?name=Sophie+Wang',
      content:
        'Been testing multiple platforms and this one stands out. Clean interface and powerful features.',
      likes: 21,
      comments: 4,
      timeAgo: '1 hour ago',
      sentiment: 'positive',
      platform: 'instagram',
      tags: ['feature']
    },
    {
      id: 10,
      author: 'Alex Kumar',
      avatar: 'https://ui-avatars.com/api/?name=Alex+Kumar',
      content:
        'Pricing is too high compared to competitors. Need more features to justify the cost.',
      likes: 15,
      comments: 11,
      timeAgo: '8 hours ago',
      sentiment: 'negative',
      platform: 'youtube',
      tags: ['pricing']
    },
    {
      id: 11,
      author: 'Lisa Garcia',
      avatar: 'https://ui-avatars.com/api/?name=Lisa+Garcia',
      content:
        'The export functionality is exactly what we needed. Makes reporting so much easier!',
      likes: 27,
      comments: 5,
      timeAgo: '12 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['feature']
    },
    {
      id: 12,
      author: 'Michael Scott',
      avatar: 'https://ui-avatars.com/api/?name=Michael+Scott',
      content:
        'System was down during our peak hours. Not reliable enough for enterprise use.',
      likes: 12,
      comments: 18,
      timeAgo: '3 days ago',
      sentiment: 'negative',
      platform: 'instagram',
      tags: ['feature']
    }
  ],
  filteredComments: [
    {
      id: 1,
      author: 'Sarah Johnson',
      avatar: 'https://ui-avatars.com/api/?name=Sarah+Johnson',
      content:
        "Thanks for reaching out! This sounds great and I'd love a demo.",
      likes: 17,
      comments: 9,
      timeAgo: '2 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['sales']
    },
    {
      id: 2,
      author: 'Mike Anderson',
      avatar: 'https://ui-avatars.com/api/?name=Mike+Anderson',
      content: 'Love the convenience of the product. Might subscribe soon!',
      likes: 2,
      comments: 0,
      timeAgo: '6 hours ago',
      sentiment: 'positive',
      platform: 'instagram',
      tags: ['product']
    },
    {
      id: 3,
      author: 'Ashley Rida',
      avatar: 'https://ui-avatars.com/api/?name=Ashley+Rida',
      content: 'Rubbish!',
      likes: 40,
      comments: 3,
      timeAgo: 'yesterday',
      sentiment: 'negative',
      platform: 'facebook',
      tags: []
    },
    // New comments...
    {
      id: 4,
      author: 'David Chen',
      avatar: 'https://ui-avatars.com/api/?name=David+Chen',
      content:
        'The sentiment analysis feature is incredibly accurate. Been using it for our marketing campaigns and seeing great results.',
      likes: 28,
      comments: 12,
      timeAgo: '3 hours ago',
      sentiment: 'positive',
      platform: 'youtube',
      tags: ['feature']
    },
    {
      id: 5,
      author: 'Rachel Martinez',
      avatar: 'https://ui-avatars.com/api/?name=Rachel+Martinez',
      content:
        'Integration was a nightmare. Support team took days to respond.',
      likes: 8,
      comments: 15,
      timeAgo: '1 day ago',
      sentiment: 'negative',
      platform: 'youtube',
      tags: ['product']
    },
    {
      id: 6,
      author: 'Tom Wilson',
      avatar: 'https://ui-avatars.com/api/?name=Tom+Wilson',
      content:
        'Can someone from the team help with API documentation? Having trouble with implementation.',
      likes: 5,
      comments: 7,
      timeAgo: '4 hours ago',
      sentiment: 'negative',
      platform: 'instagram',
      tags: ['feature']
    },
    {
      id: 7,
      author: 'Emma Thompson',
      avatar: 'https://ui-avatars.com/api/?name=Emma+Thompson',
      content:
        'Just started using the platform and already seeing valuable insights. The dashboard is intuitive!',
      likes: 45,
      comments: 8,
      timeAgo: '5 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['feature']
    },
    {
      id: 8,
      author: 'James Rodriguez',
      avatar: 'https://ui-avatars.com/api/?name=James+Rodriguez',
      content:
        'Monthly reports have transformed our feedback analysis. Worth every penny!',
      likes: 33,
      comments: 6,
      timeAgo: '2 days ago',
      sentiment: 'positive',
      platform: 'youtube',
      tags: ['feature']
    },
    {
      id: 9,
      author: 'Sophie Wang',
      avatar: 'https://ui-avatars.com/api/?name=Sophie+Wang',
      content:
        'Been testing multiple platforms and this one stands out. Clean interface and powerful features.',
      likes: 21,
      comments: 4,
      timeAgo: '1 hour ago',
      sentiment: 'positive',
      platform: 'instagram',
      tags: ['feature']
    },
    {
      id: 10,
      author: 'Alex Kumar',
      avatar: 'https://ui-avatars.com/api/?name=Alex+Kumar',
      content:
        'Pricing is too high compared to competitors. Need more features to justify the cost.',
      likes: 15,
      comments: 11,
      timeAgo: '8 hours ago',
      sentiment: 'negative',
      platform: 'youtube',
      tags: ['pricing']
    },
    {
      id: 11,
      author: 'Lisa Garcia',
      avatar: 'https://ui-avatars.com/api/?name=Lisa+Garcia',
      content:
        'The export functionality is exactly what we needed. Makes reporting so much easier!',
      likes: 27,
      comments: 5,
      timeAgo: '12 hours ago',
      sentiment: 'positive',
      platform: 'facebook',
      tags: ['feature']
    },
    {
      id: 12,
      author: 'Michael Scott',
      avatar: 'https://ui-avatars.com/api/?name=Michael+Scott',
      content:
        'System was down during our peak hours. Not reliable enough for enterprise use.',
      likes: 12,
      comments: 18,
      timeAgo: '3 days ago',
      sentiment: 'negative',
      platform: 'instagram',
      tags: ['feature']
    }
  ],
  platformIcons: {
    facebook:
      'https://res.cloudinary.com/mioven/image/upload/v1732753747/facebook.png',
    instagram:
      'https://res.cloudinary.com/mioven/image/upload/v1732753747/instagram.png',
    youtube:
      'https://res.cloudinary.com/mioven/image/upload/v1732838703/youtube_1.png',
    zendesk:
      'https://res.cloudinary.com/mioven/image/upload/v1733360412/ext-638.png'
  },
  filteredAnswers: [],
  answers: []
}

const getters = {
  comments: (state) => state.comments,
  filteredComments: (state) => state.filteredComments,
  platformIcons: (state) => state.platformIcons,
  answers: (state) => state.answers,
  filteredAnswers: (state) => state.filteredAnswers
}

const actions = {
  async getComments({ commit }, searchQuery = '') {
    try {
      let url = '/comments'
      if (searchQuery) {
        url += `?${searchQuery}`
      }

      const response = await api.get(url)

      commit('SET_FILTERED_COMMENTS', response.data.comments)
      commit('SET_COMMENTS', response.data.comments)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve comments:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve comments.'
      )
    }
  },
  async getAnswers({ commit }, searchQuery = '') {
    try {
      let url = '/answers'
      if (searchQuery) {
        url += `?${searchQuery}`
      }

      const response = await api.get(url)

      commit('SET_FILTERED_ANSWERS', response.data.comments)
      commit('SET_ANSWERS', response.data.comments)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve answers:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve answers.'
      )
    }
  },
  async deleteComment({ commit }, commentId) {
    try {
      // Make API call to delete the comment
      await api.delete(`comments/${commentId}`)
      // Commit mutation to remove comment from state
      commit('DELETE_COMMENT', commentId)
    } catch (error) {
      throw new Error('Failed to delete comment')
    }
  },

  setFilteredComments({ commit }, filteredComments) {
    commit('SET_FILTERED_COMMENTS', filteredComments)
  }
}

const mutations = {
  SET_FILTERED_COMMENTS(state, filteredComments) {
    state.filteredComments = filteredComments
  },
  SET_COMMENTS(state, comments) {
    state.comments = comments
  },
  SET_FILTERED_ANSWERS(state, filteredAnswers) {
    state.filteredAnswers = filteredAnswers
  },
  SET_ANSWERS(state, answers) {
    state.answers = answers
  },
  DELETE_COMMENT(state, commentId) {
    state.comments = state.comments.filter(
      (comment) => comment.id !== commentId
    )
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
