import api from '@/api'
import Cookies from 'js-cookie'

const isProduction = process.env.NODE_ENV === 'production'

const state = {
  user: null,
  isAuthenticated: false,
  token: localStorage.getItem('token') || null,
  showLoginModal: false,
  isLogin: false,
  socialConnections: [],
  members: []
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  currentUser: (state) => state.user,
  token: (state) => state.token,
  showLoginModal: (state) => state.showLoginModal,
  isLogin: (state) => state.isLogin,
  members: (state) => state.members
}

const actions = {
  async login({ commit }, credentials) {
    try {
      const authString = btoa(`${credentials.email}:${credentials.password}`)

      const response = await api.post('/auth/token', null, {
        headers: {
          Authorization: `Basic ${authString}`
        }
      })
      Cookies.set('auth_token', response.data.token, {
        secure: isProduction, // Only require HTTPS in production
        sameSite: isProduction ? 'strict' : 'lax', //  vs 'strict' Less strict for development
        expires: 20 // 1 day
      })

      commit('SET_TOKEN', response.data.token)
      commit('SET_USER', response.data.user)
      commit('SET_AUTH', true)

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async register({ commit }, userData) {
    try {
      const response = await api.post('/users', userData)

      Cookies.set('token', response.data.token.token, {
        secure: isProduction, // Only require HTTPS in production
        sameSite: isProduction ? 'strict' : 'lax', //  vs 'strict' Less strict for development
        expires: 20 // 1 day
      })

      commit('SET_TOKEN', response.data.token.token)
      commit('SET_USER', response.data.user)
      commit('SET_AUTH', true)

      return response
    } catch (error) {
      console.error(
        'Registration failed:',
        error.response?.data || error.message
      )
      throw new Error(
        error.response?.data?.message ||
          'Registration failed. Please check your input and try again.'
      )
    }
  },

  handleLogout({ commit }) {
    try {
      // Remove the auth token cookie
      Cookies.remove('auth_token')

      // Clear all auth-related state
      commit('SET_USER', null)
      commit('SET_AUTH', false)
      commit('SET_TOKEN', null)
      commit('SET_LOGIN_MODAL', false)
    } catch (error) {
      console.error('Logout error:', error)
      return Promise.reject(error)
    }
  },

  setLoginModal({ commit }, value) {
    commit('SET_LOGIN_MODAL', value)
  },
  setIsLogin({ commit }, value) {
    commit('SET_IS_LOGIN', value)
  },

  async getUser({ commit }, userId) {
    try {
      const response = await api.get('/users/' + userId)

      commit('SET_USER', response.data.users[0])

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve user:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve user.'
      )
    }
  },

  async getMembers({ commit }, userId) {
    try {
      const response = await api.get('/users/' + userId + '/team')

      commit('SET_MEMBERS', response.data.users[0])

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve members:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve members.'
      )
    }
  },

  async saveSocialConnections({ commit }, connections) {
    try {
      // Now connections is the array directly
      const response = await api.post('/auth/fb', {
        connections: connections // Wrap in object for API
      })

      commit('SET_SOCIAL_CONNECTIONS', response.data)
      return response.data
    } catch (error) {
      console.error('Failed to save social connections:', error)
      throw error
    }
  }
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_MEMBERS(state, members) {
    state.members = members
  },
  SET_AUTH(state, status) {
    state.isAuthenticated = status
  },

  SET_LOGIN_MODAL(state, status) {
    state.showLoginModal = status
  },
  SET_IS_LOGIN(state, status) {
    state.isLogin = status
  },
  SET_SOCIAL_CONNECTIONS(state, connections) {
    state.socialConnections = connections
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
