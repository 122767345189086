<template>
  <div
    class="min-h-[600px] flex flex-col bg-gray-900 rounded-xl border border-gray-800"
  >
    <!-- Header -->
    <div class="p-4 border-b border-gray-800">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <div
            class="w-10 h-10 rounded-full bg-indigo-500/10 flex items-center justify-center"
          >
            <svg
              class="w-6 h-6 text-indigo-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-4 4z"
              />
            </svg>
          </div>
          <div>
            <h2 class="text-lg font-semibold text-white">AI Assistant</h2>
            <div class="flex items-center gap-2">
              <div class="w-2 h-2 rounded-full bg-emerald-400"></div>
              <span class="text-sm text-gray-400">Online</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Messages Area -->
    <div
      ref="messagesContainer"
      class="flex-1 overflow-y-auto p-4 space-y-4 scroll-smooth"
    >
      <div
        v-if="!messages.length"
        class="flex flex-col items-center justify-center h-full text-gray-500"
      >
        <svg
          class="w-12 h-12 mb-2 opacity-50"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
          />
        </svg>
        <p>Start a conversation</p>
      </div>

      <template v-for="(message, index) in messages" :key="message.id">
        <!-- Message Group -->
        <div
          :class="[
            'flex',
            message.type === 'user' ? 'justify-start' : 'justify-end'
          ]"
        >
          <!-- Message Bubble -->
          <div
            :class="[
              'relative group max-w-[80%] px-4 py-3 rounded-lg',
              message.type === 'user'
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-800 text-gray-200'
            ]"
          >
            <!-- Message Content -->
            <div
              v-if="message.type === 'assistant'"
              class="markdown-content prose prose-invert prose-sm max-w-none"
              v-html="markdownToHtml(message.content)"
            ></div>
            <p v-else class="text-sm">{{ message.content }}</p>

            <!-- Timestamp -->
            <div class="mt-1 text-xs opacity-60">
              {{ formatMessageTime(message.timestamp) }}
            </div>

            <!-- Action Buttons -->
            <div
              class="absolute top-2 -right-12 hidden group-hover:flex items-center gap-1"
            >
              <button
                @click="copyMessage(message)"
                class="p-1.5 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-400 hover:text-gray-300 transition-colors"
                title="Copy message"
              >
                <svg
                  class="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  />
                </svg>
              </button>
              <button
                @click="deleteMessage(message)"
                class="p-1.5 rounded-lg bg-gray-800 hover:bg-red-900/50 text-gray-400 hover:text-red-400 transition-colors"
                title="Delete message"
              >
                <svg
                  class="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!-- Typing Indicator -->
        <div
          v-if="
            loading && index === messages.length - 1 && message.type === 'user'
          "
          class="flex items-center gap-2 pl-4"
        >
          <div
            class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center"
          >
            <svg
              class="w-5 h-5 text-indigo-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-4 4z"
              />
            </svg>
          </div>
          <div class="px-4 py-2 rounded-lg bg-gray-800 text-gray-400">
            <div class="flex gap-1">
              <span
                class="w-1.5 h-1.5 bg-gray-500 rounded-full animate-bounce"
              ></span>
              <span
                class="w-1.5 h-1.5 bg-gray-500 rounded-full animate-bounce"
                style="animation-delay: 0.2s"
              ></span>
              <span
                class="w-1.5 h-1.5 bg-gray-500 rounded-full animate-bounce"
                style="animation-delay: 0.4s"
              ></span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- Input Area -->
    <div class="p-4 border-t border-gray-800">
      <form @submit.prevent="sendMessage" class="flex gap-2">
        <div class="relative flex-1">
          <input
            v-model="messageInput"
            type="text"
            placeholder="Type a message..."
            :disabled="loading"
            class="w-full px-4 py-2.5 bg-gray-800 border border-gray-700 rounded-lg text-gray-300 placeholder-gray-500 focus:outline-none focus:border-indigo-500 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          />
        </div>
        <button
          type="submit"
          :disabled="!messageInput.trim() || loading"
          class="px-4 py-2.5 bg-indigo-600 hover:bg-indigo-500 disabled:bg-indigo-900 disabled:cursor-not-allowed text-white rounded-lg transition-colors duration-200 flex items-center gap-2 min-w-[5rem] justify-center"
        >
          <svg
            v-if="loading"
            class="animate-spin h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>{{ loading ? 'Sending' : 'Send' }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import dayjs from '@/utils/dayjs'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

const store = useStore()
const loading = ref(false)
const messageInput = ref('')
const messagesContainer = ref(null)
const messages = ref([])

// Configure marked options
marked.setOptions({
  gfm: true,
  breaks: true,
  headerIds: false,
  mangle: false
})

// Initialize DOMPurify with custom config
DOMPurify.setConfig({
  ALLOWED_TAGS: [
    'p',
    'br',
    'b',
    'i',
    'em',
    'strong',
    'a',
    'ul',
    'ol',
    'li',
    'code',
    'pre',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'hr'
  ],
  ALLOWED_ATTR: ['href', 'target', 'class']
})

const markdownToHtml = (content) => {
  const rawHtml = marked(content)
  return DOMPurify.sanitize(rawHtml)
}

const formatMessageTime = (timestamp) => {
  return dayjs(timestamp).format('h:mm A')
}

const scrollToBottom = async () => {
  await nextTick()
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
  }
}

const sendMessage = async () => {
  if (!messageInput.value.trim() || loading.value) return

  loading.value = true
  const userMessageContent = messageInput.value
  messageInput.value = ''

  // Add user message
  messages.value.push({
    id: Date.now(),
    type: 'user',
    content: userMessageContent,
    timestamp: new Date().toISOString()
  })
  await scrollToBottom()

  try {
    const response = await store.dispatch(
      'comments/getAnswers',
      `query=${userMessageContent}`
    )

    if (response?.data?.meta?.ai) {
      messages.value.push({
        id: Date.now() + 1,
        type: 'assistant',
        content: response.data.meta.ai,
        timestamp: new Date().toISOString()
      })
      await scrollToBottom()
    }
  } catch (error) {
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message || 'Failed to send message',
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  } finally {
    loading.value = false
  }
}

const copyMessage = async (message) => {
  try {
    await navigator.clipboard.writeText(message.content)
    await Swal.fire({
      icon: 'success',
      title: 'Copied!',
      text: 'Message copied to clipboard',
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'dark-swal'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  } catch (error) {
    console.error('Failed to copy:', error)
  }
}

const deleteMessage = async (message) => {
  try {
    const result = await Swal.fire({
      title: 'Delete message?',
      text: "This can't be undone",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn',
        cancelButton: 'swal-cancel-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })

    if (result.isConfirmed) {
      messages.value = messages.value.filter((m) => m.id !== message.id)
    }
  } catch (error) {
    console.error('Error deleting message:', error)
  }
}

onMounted(() => {
  scrollToBottom()
})

watch(messages, () => scrollToBottom(), { deep: true })
</script>

<style>
.markdown-content {
  @apply text-sm leading-relaxed;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  @apply font-semibold text-white mt-4 mb-2;
}

.markdown-content h1 {
  @apply text-2xl;
}
.markdown-content h2 {
  @apply text-xl;
}
.markdown-content h3 {
  @apply text-lg;
}

.markdown-content p {
  @apply mb-3;
}

.markdown-content ul,
.markdown-content ol {
  @apply mb-3 ml-4;
}

.markdown-content ul {
  @apply list-disc;
}

.markdown-content ol {
  @apply list-decimal;
}

.markdown-content li {
  @apply mb-1;
}

.markdown-content code {
  @apply px-1.5 py-0.5 rounded bg-gray-700 text-gray-200 text-sm font-mono;
}

.markdown-content pre {
  @apply p-3 rounded-full;
}
</style>