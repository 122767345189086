 <script setup>
import { ref, computed, onMounted } from 'vue'
import { MoreVertical, Plus, UserPlus } from 'lucide-vue-next'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

const store = useStore()
const members = computed(() => store.getters['auth/members'])
const searchQuery = ref('')
const openDropdowns = ref(new Set())
const user = computed(() => store.getters['auth/currentUser'])

const toggleDropdown = (memberId) => {
  if (openDropdowns.value.has(memberId)) {
    openDropdowns.value.delete(memberId)
  } else {
    openDropdowns.value.clear() // Close any other open dropdowns
    openDropdowns.value.add(memberId)
  }
}

const closeAllDropdowns = () => {
  openDropdowns.value.clear()
}

onMounted(() => {
  getMembers()

  document.addEventListener('click', (e) => {
    const dropdownButtons = document.querySelectorAll('.dropdown-button')
    const dropdownMenus = document.querySelectorAll('.dropdown-menu')

    if (
      ![...dropdownButtons, ...dropdownMenus].some((el) =>
        el?.contains(e.target)
      )
    ) {
      closeAllDropdowns()
    }
  })
})

const getMembers = async () => {
  try {
    await store.dispatch('auth/getMembers', user.value.id)
  } catch (error) {
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const filteredMembers = computed(() => {
  if (!members.value) return []

  return members.value.filter((member) => {
    if (!member) return false

    return (
      searchQuery.value === '' ||
      (member.name &&
        member.name.toLowerCase().includes(searchQuery.value.toLowerCase())) ||
      (member.email &&
        member.email.toLowerCase().includes(searchQuery.value.toLowerCase()))
    )
  })
})

const inviteMember = async () => {
  // Implement invite logic
  const { value: email } = await Swal.fire({
    title: 'Invite Team Member',
    input: 'email',
    inputLabel: 'Email address',
    inputPlaceholder: "Enter colleague's email",
    showCancelButton: true,
    customClass: {
      popup: 'dark-swal',
      confirmButton: 'swal-confirm-btn',
      cancelButton: 'swal-cancel-btn'
    },
    backdrop: `rgba(20, 18, 24, 0.8)`
  })

  if (email) {
    try {
      await store.dispatch('members/inviteMember', { email })
      await Swal.fire({
        icon: 'success',
        title: 'Invitation Sent',
        text: `An invitation has been sent to ${email}`,
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    }
  }
}

const confirmRemoveMember = async (member) => {
  const result = await Swal.fire({
    title: 'Remove Team Member',
    text: `Are you sure you want to remove ${member.name}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Remove',
    customClass: {
      popup: 'dark-swal',
      confirmButton: 'swal-confirm-btn',
      cancelButton: 'swal-cancel-btn'
    },
    backdrop: `rgba(20, 18, 24, 0.8)`
  })

  if (result.isConfirmed) {
    try {
      await store.dispatch('members/removeMember', member.id)
      await Swal.fire({
        icon: 'success',
        title: 'Member Removed',
        text: `${member.name} has been removed from the team`,
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    }
  }
}
</script>

<template>
  <div class="">
    <!-- Header with Add Button -->
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">Team Members</h2>
      <button
        @click="inviteMember"
        class="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200 font-medium"
      >
        <UserPlus class="w-5 h-5" />
        <span>Invite Member</span>
      </button>
    </div>

    <!-- Search -->
    <div class="relative mb-8">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search members..."
        class="w-full px-4 py-3 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 border border-gray-700 hover:border-gray-600 transition-colors"
      />
    </div>

    <!-- Members List -->
    <TransitionGroup name="list" tag="div" class="grid gap-4">
      <div
        v-for="member in filteredMembers"
        :key="member.id"
        class="group flex items-center justify-between p-4 sm:p-6 bg-gray-900 rounded-lg space-y-4 sm:space-y-0 transition-colors duration-200"
      >
        <!-- Left side: Avatar and Content -->
        <div class="flex items-center space-x-4">
          <div
            class="w-12 h-12 flex items-center justify-center rounded-full bg-gray-700/50 group-hover:bg-gray-700/70 transition-colors duration-200"
          >
            <span class="text-xl text-white">{{ member.name.charAt(0) }}</span>
          </div>
          <div class="min-w-0 flex-1">
            <h3
              class="text-lg font-medium text-white truncate group-hover:text-indigo-300 transition-colors duration-200"
            >
              {{ member.name }} ({{ member.role }})
            </h3>
            <p
              class="text-gray-400 text-sm group-hover:text-gray-300 transition-colors duration-200"
            >
              {{ member.email }}
            </p>
          </div>
        </div>

        <!-- Right side: Role and Menu -->
        <div class="flex items-center space-x-4">
          <!-- <span class="text-sm text-gray-400">{{ member.role }}</span> -->
          <!-- Menu Button -->
          <div class="relative">
            <button
              @click.stop="toggleDropdown(member.id)"
              class="p-2 hover:bg-gray-800 rounded-lg transition-colors dropdown-button"
            >
              <MoreVertical class="h-5 w-5 text-gray-400 hover:text-white" />
            </button>

            <!-- Dropdown Menu -->
            <div
              v-show="openDropdowns.has(member.id)"
              class="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 dropdown-menu z-10"
            >
              <div class="py-1" role="menu" aria-orientation="vertical">
                <!-- Remove -->
                <button
                  @click="confirmRemoveMember(member)"
                  class="flex items-center w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-700"
                  role="menuitem"
                >
                  <svg
                    class="mr-3 h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  Remove Member
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>

    <!-- Empty State -->
    <div
      v-if="filteredMembers.length === 0"
      class="flex flex-col items-center justify-center py-8 px-4"
    >
      <svg
        width="154"
        height="154"
        viewBox="0 0 154 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- Same SVG path as your empty state, but with users icon instead -->
        <path
          d="M77 0C34.4 0 0 34.4 0 77s34.4 77 77 77 77-34.4 77-77S119.6 0 77 0zm0 30.8c17.6 0 31.9 14.3 31.9 31.9S94.6 94.6 77 94.6 45.1 80.3 45.1 62.7 59.4 30.8 77 30.8zm0 107.8c-26.6 0-50-13.5-63.7-34.1 7.7-12.8 21.7-21.5 37.8-21.5 1.2 0 2.4.2 3.5.6 7 2.3 14.3 3.6 22.4 3.6s15.4-1.3 22.4-3.6c1.1-.4 2.3-.6 3.5-.6 16.1 0 30.1 8.7 37.8 21.5-13.7 20.6-37.1 34.1-63.7 34.1z"
          fill="#6366F1"
        />
      </svg>
      <h3 class="text-lg font-medium text-gray-300 mb-1 mt-4">
        No team members found
      </h3>
      <p class="text-gray-500">
        Try adjusting your search or invite new members
      </p>
    </div>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.3s ease;
}
</style>