<script setup>
import { ref, computed, onMounted } from 'vue'
import BarChart from './BarChart.vue'
import { useStore } from 'vuex'
import { Filter, Download } from 'lucide-vue-next'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
const store = useStore()
const loading = ref(true)
const filterType = ref('intent')

const filterOptions = [
  { value: 'intent', label: 'Intent' },
  { value: 'sentiment', label: 'Sentiment' },
  { value: 'platform', label: 'Platform' },
  { value: 'theme', label: 'Theme' }
]

const themeData = ref([])
const selectedTheme = ref(null)

const dateRange = ref({
  start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  end: new Date()
})

const generatePDF = () => {
  try {
    const doc = new jsPDF()

    // Colors
    const colors = {
      primary: [79, 70, 229],
      lightGray: [245, 247, 250],
      darkGray: [31, 41, 55],
      white: [255, 255, 255],
      indigo: [99, 102, 241],
      blue: [59, 130, 246],
      purple: [139, 92, 246]
    }

    // cover page
    doc.setFillColor(...colors.primary)
    doc.rect(0, 0, 220, 297, 'F')

    doc.setDrawColor(...colors.white)
    doc.setLineWidth(0.5)
    doc.line(20, 75, 190, 75)

    doc.setTextColor(...colors.white)
    doc.setFontSize(42)
    doc.text('Theme Analysis', 20, 50)

    doc.setFontSize(20)
    doc.text('Key Insights Report', 20, 95)

    doc.setFontSize(12)
    doc.text(`Generated ${new Date().toLocaleDateString()}`, 20, 115)

    // Key stats on cover
    const totalThemes = themeData.value.length
    const totalComments = themeData.value.reduce(
      (acc, theme) => acc + theme.size,
      0
    )

    doc.setFontSize(14)
    doc.text('Report Summary', 20, 150)

    // Stats boxes on cover
    doc.setFillColor(...colors.darkGray)
    doc.roundedRect(20, 160, 80, 45, 3, 3, 'F')
    doc.roundedRect(110, 160, 80, 45, 3, 3, 'F')

    doc.setFontSize(24)
    doc.text(totalThemes.toString(), 35, 185)
    doc.text(totalComments.toString(), 125, 185)

    doc.setFontSize(12)
    doc.text('Total Themes', 35, 175)
    doc.text('Total Comments', 125, 175)

    // Overview page
    doc.addPage()

    doc.setFillColor(...colors.darkGray)
    doc.rect(0, 0, 220, 25, 'F')
    doc.setTextColor(...colors.white)
    doc.setFontSize(16)
    doc.text('Theme Distribution', 20, 17)

    doc.autoTable({
      startY: 35,
      head: [['Theme', 'Size', 'Sentiment', 'Platform']],
      body: themeData.value.map((theme) => [
        { content: theme.ai.name, styles: { fontStyle: 'bold' } },
        theme.size.toString(),
        Object.entries(theme.metadata.sentiment_distribution || {}).sort(
          (a, b) => b[1] - a[1]
        )[0]?.[0] || '-',
        Object.entries(theme.metadata.platform_distribution || {}).sort(
          (a, b) => b[1] - a[1]
        )[0]?.[0] || '-'
      ]),
      styles: {
        fontSize: 10,
        cellPadding: 8
      },
      headStyles: {
        fillColor: [...colors.primary],
        textColor: 255,
        fontSize: 11,
        fontStyle: 'bold'
      },
      alternateRowStyles: {
        fillColor: [...colors.lightGray]
      },
      margin: { left: 20, right: 20 }
    })

    // Individual theme pages
    themeData.value.forEach((theme, index) => {
      doc.addPage()

      // Modern theme header
      doc.setFillColor(...colors.darkGray)
      doc.rect(0, 0, 220, 30, 'F')
      doc.setTextColor(...colors.white)
      doc.setFontSize(18)
      doc.text(theme.ai.name, 20, 20)

      let yPos = 45

      // Theme summary card
      doc.setFillColor(...colors.lightGray)
      doc.setDrawColor(...colors.primary)
      doc.roundedRect(15, yPos - 5, 180, 45, 3, 3, 'FD')
      doc.setTextColor(...colors.darkGray)
      doc.setFontSize(10)
      const splitSummary = doc.splitTextToSize(theme.summary, 170)
      doc.text(splitSummary, 20, yPos)

      yPos += 55

      // Stats cards
      const stats = [
        {
          label: 'Total Comments',
          value: theme.size,
          color: colors.darkGray
        },
        {
          label: 'Primary Intent',
          value: theme.metadata.intent,
          color: colors.darkGray
        },
        {
          label: 'Main Platform',
          value: Object.entries(
            theme.metadata.platform_distribution || {}
          ).sort((a, b) => b[1] - a[1])[0]?.[0],
          color: colors.darkGray
        },
        {
          label: 'Dominant Sentiment',
          value: Object.entries(
            theme.metadata.sentiment_distribution || {}
          ).sort((a, b) => b[1] - a[1])[0]?.[0],
          color: colors.darkGray
        }
      ]

      stats.forEach(({ label, value, color }, i) => {
        const x = i % 2 === 0 ? 20 : 110
        const y = yPos + Math.floor(i / 2) * 30

        doc.setFillColor(...color)
        doc.roundedRect(x - 5, y - 5, 85, 25, 2, 2, 'F')
        doc.setFontSize(8)
        doc.setTextColor(...colors.white)
        doc.text(label, x, y)
        doc.setFontSize(11)
        doc.text(value?.toString() || '-', x, y + 12)
      })

      yPos += 70

      doc.setTextColor(...colors.primary)
      doc.setFontSize(14)
      doc.text('Representative Comments', 20, yPos)

      doc.autoTable({
        startY: yPos + 10,
        head: [['Comment', 'Source', 'Sentiment']],
        body: theme.points
          .slice(0, 5)
          .map((point) => [
            point.payload.translation,
            point.payload.platform,
            point.payload.sentiment
          ]),
        columnStyles: {
          0: { cellWidth: 120 },
          1: { cellWidth: 30 },
          2: { cellWidth: 30 }
        },
        styles: {
          fontSize: 9,
          cellPadding: 6,
          lineColor: [...colors.primary],
          lineWidth: 0.1
        },
        headStyles: {
          fillColor: [...colors.darkGray],
          textColor: 255,
          fontSize: 10
        },
        alternateRowStyles: {
          fillColor: [...colors.lightGray]
        },
        margin: { left: 20, right: 20 }
      })
    })

    // Generate and download
    const pdfOutput = doc.output('blob')
    const url = window.URL.createObjectURL(pdfOutput)
    const link = document.createElement('a')
    link.href = url
    link.download = 'theme-analysis-report.pdf'
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error generating PDF:', error)
  }
}

const getThemeClusters = async () => {
  try {
    loading.value = true
    const params = {
      start_date: dateRange.value.start.toISOString().split('T')[0],
      end_date: dateRange.value.end.toISOString().split('T')[0],
      type: filterType.value
    }

    // Dispatch Vuex action to fetch theme clusters
    const response = await store.dispatch('analytics/getThemes', params)

    // Assuming the response structure matches our needs
    console.log(response)
    themeData.value = response.data.map((theme) => ({
      ...theme,
      averageGrowth: parseFloat(theme.size || 0),
      commentCount: theme.points.length
    }))

    // Select first theme by default
    if (themeData.value.length > 0) {
      selectedTheme.value = themeData.value[0]
    }

    // Prepare chart data
    chartData.value = {
      labels: themeData.value.map((theme) => theme.ai.name),
      datasets: [
        {
          label: 'Theme Size',
          data: themeData.value.map((theme) => theme.commentCount),
          backgroundColor: '#3b82f6'
        }
      ]
    }

    loading.value = false
  } catch (error) {
    console.error('Error fetching theme clusters:', error)
    loading.value = false
  }
}

const handleFilterChange = () => {
  getThemeClusters()
}

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: 'Theme Size',
      data: [],
      backgroundColor: '#3b82f6'
    }
  ]
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: '#fff'
      }
    }
  },
  scales: {
    y: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    },
    x: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    }
  }
}

// Computed properties for selected theme analysis
const sentimentDistribution = computed(() => {
  if (!selectedTheme.value) return []

  const sentiments = selectedTheme.value.points.reduce((acc, point) => {
    const sentiment = point.payload.sentiment
    acc[sentiment] = (acc[sentiment] || 0) + 1
    return acc
  }, {})

  return Object.entries(sentiments).map(([name, value]) => ({ name, value }))
})

const platformDistribution = computed(() => {
  if (!selectedTheme.value) return []

  const platforms = selectedTheme.value.points.reduce((acc, point) => {
    const platform = point.payload.platform
    acc[platform] = (acc[platform] || 0) + 1
    return acc
  }, {})

  return Object.entries(platforms).map(([name, value]) => ({ name, value }))
})

onMounted(() => {
  getThemeClusters()
})
</script>

<template>
  <div v-if="loading">
    <div class="spinner mb-4"></div>
    This may take a minute...
  </div>
  <div v-else class="min-h-screen">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">Theme Analysis</h2>

      <button
        @click="generatePDF"
        class="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200"
        :disabled="!selectedTheme"
      >
        <Download class="w-5 h-5" />
        <span>Export Report</span>
      </button>

      <!-- Filters Container -->
      <!-- <div class="flex-wrap items-center gap-4">
      
        <div class="flex-wrap items-center gap-2">
          <div class="relative">
            <select
              v-model="filterType"
              @change="handleFilterChange"
              class="appearance-none bg-gray-800 text-white px-4 py-2 pr-10 rounded-lg border 
              border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer hover:bg-gray-750"
            >
              <option
                v-for="option in filterOptions"
                :key="option.value"
                :value="option.value"
                class="bg-gray-800 hover:bg-gray-700"
              >
                {{ option.label }}
              </option>
            </select>
        
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"
            >
              <svg
                class="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- Themes Overview Chart -->
    <div class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">Theme Distribution</h3>
      <div class="h-[400px]">
        <BarChart :chart-data="chartData" :chart-options="chartOptions" />
      </div>
    </div>

    <!-- Theme Selector -->
    <div class="flex gap-4 mb-6 flex-wrap">
      <button
        v-for="theme in themeData"
        :key="theme.id"
        @click="selectedTheme = theme"
        :class="[
          'px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200',
          selectedTheme?.id === theme.id
            ? 'bg-blue-600 text-white'
            : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
        ]"
      >
        {{ theme.ai.name }}
        [{{ theme.id.replace('theme_', '').replace('_', ' ') }}]
      </button>
    </div>

    <!-- Selected Theme Details -->
    <div v-if="selectedTheme" class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Theme Overview -->
      <div class="bg-gray-900 rounded-lg p-4">
        <h3 class="text-lg font-semibold text-white mb-4">Theme Overview</h3>
        <div class="space-y-2">
          <div class="flex justify-between">
            <span class="text-gray-400">Total Comments</span>
            <span class="font-bold text-white">{{
              selectedTheme.commentCount
            }}</span>
          </div>
          <div class="flex justify-between">
            {{ selectedTheme.ai.summary }}
          </div>
        </div>
      </div>

      <!-- Sentiment Distribution -->
      <div class="bg-gray-900 rounded-lg p-4">
        <h3 class="text-lg font-semibold text-white mb-4">
          Sentiment Distribution
        </h3>
        <div class="space-y-2">
          <div
            v-for="sentiment in sentimentDistribution"
            :key="sentiment.name"
            class="flex justify-between items-center"
          >
            <span class="text-white">{{ sentiment.name }}</span>
            <span class="font-bold text-blue-400">{{ sentiment.value }}</span>
          </div>
        </div>
      </div>

      <!-- Platforms Distribution -->
      <div class="bg-gray-900 rounded-lg p-4">
        <h3 class="text-lg font-semibold text-white mb-4">Platforms</h3>
        <div class="space-y-2">
          <div
            v-for="platform in platformDistribution"
            :key="platform.name"
            class="flex justify-between items-center"
          >
            <span class="text-white">{{ platform.name }}</span>
            <span class="font-bold text-blue-400">{{ platform.value }}</span>
          </div>
        </div>
      </div>

      <!-- Theme Comments -->
      <div class="bg-gray-900 rounded-lg p-4 md:col-span-2">
        <h3 class="text-lg font-semibold text-white mb-4">Comments</h3>
        <div class="space-y-4">
          <div
            v-for="comment in selectedTheme.points"
            :key="comment.id"
            class="bg-gray-800 p-3 rounded-lg"
          >
            <div class="flex justify-between mb-2">
              <span class="text-white font-medium">
                {{ comment.payload.author.name }}
              </span>
              <span class="text-sm text-gray-400">
                {{ new Date(comment.payload.commentTime).toLocaleDateString() }}
              </span>
            </div>
            <p class="text-gray-300">{{ comment.payload.translation }}</p>
            <div class="flex justify-between mt-2">
              <span class="text-sm text-blue-400">
                {{ comment.payload.platform }}
              </span>
              <span class="text-sm text-green-400">
                {{ comment.payload.sentiment }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>